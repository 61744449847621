import classNames from 'classnames';

const CaretLeft = ({ className = '' }) => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    className={classNames('icon', className)}
    role="presentation"
    aria-hidden={true}
  >
    <title>Left Caret</title>
    <g fillRule="evenodd">
      <path
        fill="currentColor"
        d="M15.7071062,6.70710642 C16.0976305,6.31658213 16.0976305,5.68341715 15.7071062,5.29289286 C15.3165819,4.90236857 14.683417,4.90236857 14.2928927,5.29289286 L8.29289268,11.2928929 C7.90236839,11.6834172 7.90236839,12.3165821 8.29289268,12.7071064 L14.2928927,18.7071064 C14.683417,19.0976307 15.3165819,19.0976307 15.7071062,18.7071064 C16.0976305,18.3165821 16.0976305,17.6834172 15.7071062,17.2928929 L10.414213,11.9999996 L15.7071062,6.70710642 Z"
      />
    </g>
  </svg>
);

CaretLeft.propTypes = {
  className: PropTypes.string,
};

export default CaretLeft;
