import { useContext } from 'react';

import { BrandContext } from 'context/BrandContext';

const useBrand = targetBrand => {
  const { brand } = useContext(BrandContext);

  return brand === targetBrand;
};

export default useBrand;
